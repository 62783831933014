import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import pixyleCharacter from '../../assets/Upload-Limit.svg'
import { useStore } from "react-context-hook";

const LimitExceededModal = ({exitFunc, msg}) => {


    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
      }

    const exit = () => {
        document.body.style.overflowY = "auto";
        exitFunc();
    }

    useEffect(()=>{
        window.scrollTo(0, 0);
        document.body.style.overflowY = "hidden";
    }, [])

    return ReactDOM.createPortal(
        <div onClick={(e) =>{e.stopPropagation(); exit()}} className="ui dimmer modals visible active">
        <div onClick={(e) => e.stopPropagation()} className="ui standard modal visible active modal-class upload-new-limit" style={{minWidth:"10%"}}>         
            <div className="content modal-wrapper upload-new"
                    style={{display:"flex",justifyContent:"space-around", height:"92%", width:'100%'}}
            >
                <div className="close-div">
                        <i className="close icon icon-modal-new"
                        onClick={() => exit()}
                        style={{fontSize:"2rem"}}>
                        </i>
                </div>
                <img src={pixyleCharacter} 
                    style={{height:'70%'}} alt="illustration" className="illustration-limit"/>
                    <div className="message">
                        <div className="title-limit" style={{paddingTop: "5rem"}}>Please wait a minute before you try again.</div>
                        <div className="subtitle-limit" style={{paddingTop: "5rem"}}>
                        <button className="urlButtonAutomatic at limit" onClick={() => exit()}>Okay, take me back to the demo</button>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>, document.getElementById("modal")

    )
}

export default LimitExceededModal;