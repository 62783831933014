import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import HubspotForm from "../hubspot/HubspotForm";
import successSub from "../../assets/successSub.png";
const Modal = (props) => {
    const[submited, setSubmitted] =  useState(false)

    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
      }
    useEffect(()=>{
        window.scrollTo({top:0});
        document.body.style.overflowY = "hidden";
        window.scrollTo(0, 0);
    }, [1])
    const handleCancel = () =>{
        props.setOpenCustomDemoForm(false)
        // props.setLimitExceeded(false)
        document.body.style.overflowY = "auto";
    }
    
    return ReactDOM.createPortal(
        <div className="ui dimmer modals visible active"  onClick={() => handleCancel()}>
            <div onClick={(e) => e.stopPropagation()} className="ui standard modal visible active mail-modal" style={{ position:"relative"}}>
                <div className="closeModal" ><i className="close icon" onClick={() => handleCancel()}></i></div>
       
                <div className="content direction-row mail-content">
                {!submited ?
                    <div className="contact-form">
                        <div className="other-content">
                            <h1 className="mail-title">Want to see more of Pixyle?</h1>
                            <div className="mail-subtitle">
                                <p>  Make it easy for your shoppers to find what they’re looking for, in no-time.
                                <br/>Book a free consultation call with one of our product specialists.
                                </p>
                            </div> <br/>
                        </div>
                        <HubspotForm setSubmitted={setSubmitted} handleCancel={handleCancel} />
                        
                    </div>
                    :
                    <div className='submited-form'>
                        <img src={successSub} alt="success-submited-form" />
                        <div className='success-sub-msg'>
                          <p> Thank you for booking a meeting with us! </p>
                          <p> Our team is eager to learn more about your business needs and how our software can help you.</p>
                          <p>You will receive a confirmation email shortly with all the details.</p>
                          <p>We look forward to speaking with you soon.</p>
                        </div>
                    </div>}
                    
                </div>
                
            </div>
        </div>, document.getElementById("modal")
       
    )
}
export default Modal;