// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./webfonts/Avenir-Light.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./webfonts/Avenir-Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./webfonts/Avenir-Medium.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./webfonts/Avenir-Medium.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./webfonts/ForedaySerif-Black.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./webfonts/ForedaySerif-Black.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Avenir-Light\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\"); }\n\n@font-face {\n  font-family: \"Avenir-Medium\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff2\"); }\n\n@font-face {\n  font-family: \"Foreday Serif Black\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff2\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff\");\n  font-weight: normal;\n  font-style: normal; }\n", "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACI,2BAA2B;EAC3B,oHAC4D,EAAA;;AAG9D;EACE,4BAA4B;EAC5B,oHAC6D,EAAA;;AAG/D;EAEA,kCAAkC;EAClC,oHAA8H;EAC9H,mBAAmB;EACnB,kBAAkB,EAAA","sourcesContent":["@font-face {\n    font-family: \"Avenir-Light\";\n    src: url(\"../src/webfonts/Avenir-Light.woff\") format(\"woff\"),\n      url(\"../src/webfonts//Avenir-Light.woff2\") format(\"woff2\");\n  }\n  \n  @font-face {\n    font-family: \"Avenir-Medium\";\n    src: url(\"../src/webfonts/Avenir-Medium.woff\") format(\"woff\"),\n      url(\"../src/webfonts//Avenir-Medium.woff2\") format(\"woff2\");\n  }\n\n  @font-face\n{\n  font-family: \"Foreday Serif Black\";\n  src: url(\"../src/webfonts/ForedaySerif-Black.woff2\") format('woff2'), url('./webfonts/ForedaySerif-Black.woff') format('woff');\n  font-weight: normal;\n  font-style: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
