import {baseURL, masterDemoToken} from "./baseURL";

export const getLimit = async () => {
    let responseStatus;
    try{
      return await fetch(`${baseURL}/solutions/demo/auto-tag/limits`, {
            method: "GET",
            headers: new Headers({
                "Authorization":`Bearer ${masterDemoToken}`
            }),
        })
        .then(response =>
            {
              responseStatus = response.status;
                // if(responseStatus === 204) {
                //     return {
                //         "detail":"Unprocessable Entity",
                //         "responseStatus":response.status
                //     }
                // }
                return response.json()
            })
        .then(responseJson => {
            if(responseStatus===200){
                return {responseStatus,responseJson,hasError:false}
               }
            else return {responseStatus,responseJson,hasError:true,msg:"Error"}
        })
        .catch(error => {
            console.error("error: "+ error);
            return error;
        })
    }
    catch(err) {
        console.error(`Error  ${err}`);
        return err;
    }
}