export const sendToHubspot = async (data) => {

    let responseStatus;
{/* <script charset="utf-8" type="text/javascript" src="//js-eu1.hsforms.net/forms/shell.js"></script>
<script>
  hbspt.forms.create({
	region: "eu1",
	portalId: "25662307",
	formId: "aba1fc03-c1e6-47ea-9024-8c70bba3376c"
}); */}
// </script>
{/* <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js"></script>
<script>
  hbspt.forms.create({
    region: "na1",
    portalId: "6324919",
    formId: "8a2a1b70-f888-40db-8e50-0ebcac987689"
  });
</script> */}
    try {
        return await fetch("https://api.hsforms.com/submissions/v3/integration/submit/6324919/61100788-1c40-4890-822b-4b15c8bd05bb", {
            method:"POST",
            headers: {
                "Content-Type":"application/json"
            },
            body:data
        })
        .then(res => {
            responseStatus = res.status;
            return res.json()
        })
        .then(res => {
            return {
                responseStatus,
                ...res
            }
        })
    } catch (e) {
        console.log(e)
    }
}