import React, { useEffect, useState } from 'react';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';


export const PhoneInput=({disabled,...props})=>{

    const [options,toggleOptions]=useState({
        allowDropdown:true,
        autoHideDialCode:false,
        initialCountry: "auto",
        separateDialCode:true,
        nationalMode:false,
        hadInitialPlaceholder:true,
        geoIpLookup: function(callback) {
            fetch("https://ipapi.co/json")
              .then((res)=>{ return res.json(); })
              .then((data)=>{ callback(data.country_code); })
                //   .then((data)=>{ rest.setPhoneNumber(data.country_calling_code); })
                .catch(()=>{ callback("us"); });
          }, utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.0.2/js/utils.js',
        customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
            return "e.g. " + selectedCountryPlaceholder;
        },
    })
    useEffect(()=>{
        const input = document.querySelector("#phoneNumber");
        if(!input) return;
        const iti=intlTelInput(input, {
            ...options
        });
        return()=>{
            iti.destroy();
        }
    },[])
    useEffect(()=>{
          toggleOptions(o=>({
              ...o,
              allowDropdown:!disabled
              //disable dropdown when disable flag is set
          }));
    },[disabled])
    
    return(
        
         <input
            className={"form phone-input"}
            // placeholder='Add your phone number *' 
            disabled={disabled}
            type="phone"
            id="phoneNumber"
            {...props}
        />
    )
}