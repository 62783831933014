import React, {useState, useEffect} from 'react'
import { useStore} from 'react-context-hook'
import checkCircle from '../../assets/check-circle.png'
const Completed = (props) => {

  return (
      
      <div className='uploadingProgress'>
           <div className='uploading'>
                <div><img src={checkCircle} alt="" /></div>
                <div  className='uploadMsg' >Image Succesfully Uploaded</div>
            </div> 
      </div>
  )

}
export default  Completed ;