import {baseLoginApiURL} from './baseURL'
const login = (data) => {
    try {
      return fetch(`${baseLoginApiURL}/login`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          // Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          return responseJson;
        })
        .catch(error => {
          // console.log("Login error: ", error);
          return error;
        });
    } catch (errors) {
      // console.log("Login error in try-catch: ", errors);
      return errors;
    }
  };

  export default login;