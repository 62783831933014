import React, {useEffect, useState} from 'react';
import { set } from 'react-ga';
import {sendToHubspot} from "../../endpoints/hubspot";
import Autotag from '../Autotag/Autotag';
import gtag, { install } from 'ga-gtag';
import { PhoneInput } from './PhoneInput';
const HubspotForm = (props) => {
    const {setSubmitted, moveOn, handleCancel} = props;
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [message, setMessage] = useState("");
    
    const [errNum, setErrNum] = useState(false);
    const[errEmail, setErrEmail]= useState(false)
    // const [autotag, setAutotag] = useState(true)
    // const [vs, setVS] = useState(false)
    // const [sr, setSR] = useState(false)
    const [describesYou, setDescribesYou]= useState('')
    const [catalogSizeVal, setCatalogSize]= useState('')
    const [utm_medium, setUTMmedium]= useState(' ') 
    const [utm_source, setUTMsource]= useState(' ')
    const [utm_campaign, setUTMcampaign]= useState(' ')
    const[showErr, setShowErr]= useState(false)

    let describes=[
        'Which best describes you',
        'Brand',
        'Retailer',
        'Marketplace',
        'Startup',
        'Other'
    ]
    let catalogSize=[
       'Please select catalog size',
       '0-10,000 SKUs',
        '10,000-50,000 SKUs',
        '50,000-100,000 SKUs',
       'More than 100,000 SKUs'
    ]
    useEffect(()=>{
        install('UA-115993727-2');
        let c = document.cookie;
        const cookies = document.cookie.split(";").reduce((ac, cv, i) => {
            const [key, val] = cv.split("=");
            return Object.assign(ac,{[key]: val });
        }, {});

        Object.entries(cookies).map(([key, value]) => {
            if (key.includes('utm_source')){
                setUTMsource(value)
            }
            if (key.includes('utm_medium')){
                setUTMmedium(value)
            }
            if (key.includes('utm_campaign')){
                setUTMcampaign(value)
            }
        })

    },[])

    const handleSubmit = (e) =>{
        e.preventDefault()
        setErrNum(false);
        setErrEmail(false)
        // let aTag = autotag?'Automatic Tagging': '';
        // let vSearch=  vs?'Visual Search':'';
        // let sRecomendations = sr?'Similar Recomendations':''
        let bodyData = {}
        bodyData.fields = [];
        const title = document.getElementsByClassName("iti__selected-dial-code");
        let country_code = title[0].innerHTML
        if (email.includes("clotheswardrobe")) {
            setErrEmail(true)
            return ;
        } 
        bodyData.fields.push({name:"email", value: email});
        bodyData.fields.push({name:"firstname", value: firstName})
        bodyData.fields.push({name:"lastname", value: lastName})
        bodyData.fields.push({name: "company", value: company})
        bodyData.fields.push({name: "phone", value: country_code + phoneNumber})
        bodyData.fields.push({name: "which_best_describes_you_", value: describesYou})
        bodyData.fields.push({name: "please_select_catalog_size", value: catalogSizeVal})
   
        // bodyData.fields.push({name: "utm", value: `${utm_source}; ${utm_medium}; ${utm_campaign}`})
    
        let data = JSON.stringify(bodyData);
        if(!bodyData.fields.some(item => item.value === undefined)){
            sendToHubspot(data).then((res)=>{
                if(res.responseStatus === 200) {
                    // moveOn()
                    setSubmitted(true);
                    // localStorage.setItem('email', email)
                } else  if(res.errors){
                
                    // setErrEmail(true)
                    res.errors.map((key)=>{
                        // console.log(key)
                        if(key.errorType === "INVALID_EMAIL"){
                            setErrEmail(true)
                        }else if(key.errorType === "NUMBER_OUT_OF_RANGE"){
                            setErrNum(true)
                        }

                    })
            
                }
            })
        }
    }
    // const onChangeHandler = (value, sol) => {
    //     value == 'false' ? value = true : value = false
    //     if( sol==='at' ) setAutotag(value)
    //     else if( sol==='vs') setVS(value)
    //     else if( sol==='sr') setSR(value)
      
    // }
    const trackClick = () => {
            gtag('event', 'Click Schedule My Demo',   
            {'event_category': 'button', 
            'event_label': 'Schedule My Demo'})
        }
    const validateEmail =(e) => {
        const re = /^(?!.+@(gmail|google|yahoo|outlook|hotmail|mail|msn)\..+)(.+@.+\..+)$/;
        const mailTest = re.test(String(e).toLowerCase());
        setEmail(e)
        if(mailTest === true) {
            setErrEmail(false) 
        } else {
            setErrEmail(true)
        }
    }
    // const validateNum = (e) =>{
    //     const re= /^[0-9\b]+$/
    //     setPhoneNumber(e)
    //     if(e !== ""){
    //         if (!re.test(e) || e.length<8) {
    //             console.log(e)
    //             setErrNum(true)
    //         }else{
    //             setErrNum(false)
    //         }
    //     }
    // }

    const mapList = (options) => {
        return options.map((val, index) => {
            if (val == 'Please select catalog size' || val == 'Which best describes you'){
                return  <option className="text-option"  key={index}   value="" disabled selected hidden>
                            {val}
                        </option>
            }else{
                return <option className="text-option" key={index} value={val}>
                            {val}
                        </option>
            }
            })
        }
        
    const onChangeHandlerSelect = (value, selectInput) => {
        if(selectInput == 'catalog'){
            setCatalogSize(value)
        }else {setDescribesYou(value)}
    }
    return (
    
        <form onSubmit={handleSubmit} className="hubForm">
            <div className="name">
                <div className="hubInput">
                    <label htmlFor="firstName" className="hubspotLabel"> First Name <b className="bluestar">*</b></label>
                    <input className={firstName === "" ? 'form longer' : 'form-full'} 
                        name="firstName" placeholder='First Name *'
                        type="text" id="firstName" value={firstName} onChange={(e)=>setFirstName(e.target.value)} required></input>
                </div>
                <div className="hubInput">
                    <label htmlFor="lastName" className="hubspotLabel"> Last Name <b className="bluestar">*</b></label>
                    <input className={lastName === "" ? 'form longer' : 'form-full'} 
                        name="lastName" placeholder='Last Name *'
                        type="text" id="lastName" value={lastName} onChange={(e)=>setLastName(e.target.value)} required></input>
                </div>
            </div>
            <div className="hubInput">
                <label htmlFor="email" className="hubspotLabel">Work E-mail <b className="bluestar">*</b></label>
                <input className={email === "" ? 'form longer' :  showErr?'err form' : "form-full"} 
                    name="email" type="text" id="email" placeholder='Business e-mail *'  onBlur={(e)=>errEmail? setShowErr(true):setShowErr(false)}
                     onChange={(e)=>validateEmail(e.target.value)} required></input>
                      <p className={showErr? 'ShowInput': 'hideInput'}>Please enter your business email address.</p>
            </div>
            <div className="hubInput">
                <label htmlFor="company" className="hubspotLabel"> Company <b className="bluestar">*</b></label>
                <input className={company === "" ? 'form' : 'form-full'} name="company" 
                    type="text" id="company" value={company} placeholder='Company *'
                    onChange={(e)=>setCompany(e.target.value)} required></input>
            </div>
            <div className="hubInput">
                <label htmlFor="phoneNumber" className="hubspotLabel"> Phone Number</label>
                {/* <input className={phoneNumber === "" ? 'form' : errNum?'err form' : "form-full"} name="phoneNumber"
                 type="number" id="phoneNumber" placeholder='Add your phone number *' 
                 value={phoneNumber} onChange={(e)=>setPhoneNumber(e.target.value)} required ></input> */}
                 <PhoneInput 
                    name="phone_no"
                    onChange={(e)=>setPhoneNumber(e.target.value)}
                    value={phoneNumber}
                    // disabled={loading}
                    // error={errors['phone_no']}
                    />
            </div>
        
            <div className="hubInput" >
                 <select className='form select-input'
                        name='Which-Best-Describes-You'
                        data-name="Which Best Describes You"
                        onChange={event => onChangeHandlerSelect(event.target.value, 'describes')}
                        required={true}
                    >
                    {mapList(describes) }
                 </select>
            </div>
            <div className="hubInput" >
                 <select className='form select-input'
                        name="Please-select-catalog-size-2"
                        data-name="Please Select Catalog Size 2"
                        onChange={event => onChangeHandlerSelect(event.target.value, 'catalog')}
                        required={true}
                        >
                    {mapList(catalogSize) }
                 </select>
            </div>
            <div className="privacy-policy pp-form">
                <p>By submitting this form you consent to Pixyle processing your data 
                    and contacting you to fulfill your request. For more information please review our  
                    <a className="privacy-link" href="https://www.pixyle.ai/privacy"> Privacy Policy</a>.</p>
            </div>
            <div className="buttons-actions">
            <button id='schedule-my-demo' type="submit" className="submitBtn" 
                  disabled={errEmail?true:false}
                  onClick={trackClick}
                   > Book a call with sales </button>
                </div>
            <div className="privacy-policy pp-mobile">
                <p>By submitting this form you consent to Pixyle processing your data 
                    and contacting you to fulfill your request. For more information please review our  
                    <a className="privacy-link" href="https://www.pixyle.ai/privacy"> Privacy Policy</a>.</p>
            </div>
        </form>

    )
}
export default HubspotForm;