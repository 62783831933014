import React from 'react';
// import { useStore } from 'react-context-hook';

import wave from '../../assets/footer.svg';
import logo from '../../assets/smallLogo.png'
const Footer = ({open}) => {
    // const [open] = useStore('navbarOpen',false)
    return (
        <div style ={ open? {marginTop:"60rem"} : null}>
            <div className="footer">
			<img src={wave} alt="waves" className="wave" />
			<div className="grayDiv">
				<div className="aboutPixyle">
                    <h2 className="h2footer">Want to learn more about us?</h2>
                    <div className='visitPixyle'><a href="https://www.pixyle.ai"> Visit Pixyle.ai →  </a></div>
                </div>
                <div className="navbar footer-links">
                    <div className="address">
                        <div className="logoSmall">
                            <img src={logo} alt="logo" />
                        </div>
                        <div className='addressInfo'>
                                Wibautstraat 131 D, 1091GL <br/> 
                                Amsterdam  |  The Netherlands<br/> 
                            <div>2024 Pixyle.ai All rights Reserved</div>

                        </div>
                    </div>
                    <div className="links">
                        <a href="https://www.pixyle.ai/company/our-story" target="_blank">Our Story</a>
                        <a href="https://www.pixyle.ai/product/product-tagging " target="_blank" >Product Tagging</a>
                        <a href="https://www.pixyle.ai/resources/blog" target="_blank">Blog</a>
                        <a href="https://www.pixyle.ai/contact" target="_blank">Contact Us</a>
                        <a href="https://www.pixyle.ai/privacy" target="_blank">Privacy Policy</a>
                        <a href="https://www.pixyle.ai/cookie-policy" target="_blank">Cookie Policy</a>
                    </div>
                </div>
			</div>
            </div>
        </div>
    )
}

export default Footer
