// import '../endpoints/accountSettings'
//import accountSettings from '../endpoints/accountSettings';
 
export const getToken  = () => {   
    const token = JSON.parse(localStorage.getItem("loginToken"));
    if (token && token["access_token"] && token["access_token"].length > 0) 
    { 
     return true
    }
        else{
            return false
        }

}
