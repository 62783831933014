import React, {useEffect, useState} from 'react';
// import { useStore } from 'react-context-hook';
import ReactDOM from "react-dom";
const CookiePreferences = (props) => {
    const {marketing, setMarketing,statistics, setStatistics,denyAll, acceptAll, setOpenPreferences,
        setShowCookieIcon, confirmpreferences} = props.prefsObj
    useEffect(()=>{
        if (window.localStorage.getItem('marketing'))
        window.localStorage.getItem('marketing') == 'true' ? setMarketing(true) : setMarketing(false)
        if( window.localStorage.getItem('statistics'))
        window.localStorage.getItem('statistics') == 'true' ? setStatistics(true) : setStatistics(false)
    }, [])

    
    const closePreferences = () =>{
        document.body.style.overflowY = "auto";
       setOpenPreferences(false)
       setShowCookieIcon(true)
    }

    return ReactDOM.createPortal(
        <div className="ui dimmer modals visible active preferences-dimmer"  onClick={() => closePreferences()}>
            <div onClick={(e) => e.stopPropagation()} className=" visible active preferences-modal" style={{ position:"relative"}}>
                <div className=" cookie-preferences-content">
                    <form id="cookie-preferences" name="form-Cookie-Preferences" className="cookie-prefs-form">
                        <div className="cookie-prefs-close">
                            <div className="cookie-prefs-close-icon" onClick={() => closePreferences() }>
                                <i className="close icon" ></i>
                            </div>
                        </div>
                        <div className='preferences-content'>
                            <div className="preferences-title">Privacy Preference Center</div>
                            <div className="preferences-text text-space-sm">When you visit websites, they may store or retrieve data in your browser. This storage is often necessary for the basic functionality of the website. The storage may be used for marketing, analytics, and personalization of the site, such as storing your preferences. Privacy is important to us, so you have the option of disabling certain types of storage that may not be necessary for the basic functioning of the website. Blocking categories may impact your experience on the website.</div>
                            <div className='preferences-btn'>
                            <div className='cookie-banner-btn-deny prefs-btn columnUploadedImageButton' onClick = {()=> {denyAll(); closePreferences()}}>Reject all cookies</div>
                            <div className='cookie-banner-btn-accept prefs-btn columnUploadedImageButton' onClick = {()=> {acceptAll(); closePreferences()}}>Allow all cookies</div>
                            </div>
                            <div className='preferences-options'>
                                <div className="preferences-title">Manage Consent Preferences by Category</div>
                                <div className="preferences-option">
                                    <div className="preferences-toggle-wrapper">
                                        <div className="preferences-label">Essential</div>
                                        <div className="preferences-text"><strong>Always Active</strong></div>
                                    </div>
                                    <div className="preferences-text text-space-sm">
                                        These items are required to enable basic website functionality.
                                    </div>
                                </div>
                                <div className="preferences-option">
                                    <div className="preferences-toggle-wrapper">
                                        <div className="preferences-label">Marketing</div>
                                        <label className="switch">
                                            <input type="checkbox"
                                                value={marketing}
                                                checked={marketing} 
                                                onChange={() => setMarketing(!marketing)} 
                                            />
                                            <span className="slider"></span>
                                        </label>
                                    </div>
                                    <div className="preferences-text text-space-sm">
                                        These items are used to deliver advertising that is more relevant to you and your interests. 
                                        They may also be used to limit the number of times you see an advertisement and 
                                        measure the effectiveness of advertising campaigns. 
                                        Advertising networks usually place them with the website operator’s permission.
                                    </div>
                                </div>
                                <div className="preferences-option">
                                    <div className="preferences-toggle-wrapper">
                                        <div className="preferences-label">Statistics</div>
                                        <label className="switch disable">
                                            <input type="checkbox" 
                                                disabled
                                                value={statistics}
                                                checked={statistics} 
                                                onChange={() => setStatistics(!statistics)} 
                                            />
                                            <span className="slider disable"></span>
                                        </label>
                                    </div>
                                    <div className="preferences-text text-space-sm">
                                        These items help the website operator understand how its website performs, how visitors interact 
                                        with the site, and whether there may be technical issues. 
                                        This storage type usually doesn’t collect information that identifies a visitor.
                                    </div>
                                </div>
                            </div>
                            <div className='cookie-banner-btn-confirm columnUploadedImageButton' onClick={() => {confirmpreferences(); closePreferences()} }  >Confirm preferences and close</div>

                        </div>
                    </form>

                </div>
                
            </div>
        </div>, document.getElementById("modal")
       
    )
}

export default CookiePreferences
