import React, { useEffect, useState } from 'react';
import {useStore} from 'react-context-hook';
import CategoriesDetected from '../CategoriesDetected/CategoriesDetected'
const ATGridPanel = () => {

    const [reset,setReset] = useState(false);

    const [categorySelected] = useStore('categorySelected', '') 
    const [selectedAttributesObject] = useStore('selectedAttributesObject', {})
    const [uploadedImageURL] = useStore('uploadedImageURL', null)
    const [uploadResponse] = useStore('uploadResponse', {});
    const [categoryIndex, setCategoryIndex] = useStore('categoryIndex', 0)
    const [responseResult, setResponseResult] = useState([])
    const [firstLoaded, setFirstLoaded] = useStore('loaded', false)
    const [ is_blurred, setIs_blurred] = useStore(false)
    const [, setOpenCustomDemoForm] = useStore('book demo',false)

    const createDiv = () => {
        let boardDiv = document.createElement("div");
        return boardDiv;
    }
    const createBoundingBoxes = () => {
        var img = document.getElementById("image-upload")
            let narH = img.naturalHeight;
            let narW =img.naturalWidth;

            if(narH===0 && narW===0){
                // console.log('reseting here')
                let newReset = reset?false:true;
                setReset(newReset);
            }

            if(narH>0 && narW > 0) {
            var scaleFactorHeight = img.offsetHeight / narH;
            var scaleFactorWidth = img.offsetWidth / narW;

            var widthDivider = narW / img.offsetWidth;
            var heightDivider = narH / img.offsetHeight;

            let imgDiv = document.getElementById("uploaded-image")
            var boundingBoxes=[];
            if(!scaleFactorWidth || !scaleFactorHeight || !widthDivider || !heightDivider ){
                let newReset = reset?false:true;
                setReset(newReset);
            }

            if(imgDiv.childNodes.length===1) {
                responseResult.forEach((categoryObj, index)=>{
                    let coordinates
                    if(categoryObj.product_coordinates !== undefined){
                        coordinates = categoryObj.product_coordinates
                    }else{
                        coordinates = categoryObj.coordinates
                    }
                    // console.log(coordinates)
                    boundingBoxes.push(createDiv())
                    boundingBoxes[index].id = parseInt(index);
                    boundingBoxes[index].innerText= parseInt(index) + 1;
                    
                    if(categoryIndex===parseInt(index))
                    {
                        // console.log("coordinates",coordinates)
                        boundingBoxes[index].className="bounding-box-active"

                        boundingBoxes[index].style.width= (Math.round((coordinates.x_max-coordinates.x_min)*scaleFactorWidth))+"px";
                        boundingBoxes[index].style.height= (Math.round((coordinates.y_max-coordinates.y_min)*scaleFactorHeight))+"px"
                        boundingBoxes[index].style.top=(Math.round((coordinates.y_min)/heightDivider)).toString() + "px"
                        boundingBoxes[index].style.left=(Math.round((coordinates.x_min /widthDivider))).toString()+"px"; 
                        // console.log( boundingBoxes[index].style.top,  boundingBoxes[index].style.left,  boundingBoxes[index].style.width ,  boundingBoxes[index].style.height, "----------------------------")
                        // console.log(coordinates.x_max, coordinates.x_min, coordinates.y_max,coordinates.y_min,  scaleFactorWidth, scaleFactorHeight , heightDivider, widthDivider, "------------------------------------" )
                        boundingBoxes[index].style.opacity="0.7";

                    }
                    else {
                        boundingBoxes[index].className="bounding-box"
                        boundingBoxes[index].style.width = "4rem";
                        boundingBoxes[index].style.height = "4rem";
                        boundingBoxes[index].style.top=(Math.round(((coordinates.y_min+coordinates.y_max)/(2*heightDivider)))).toString()+"px"; 
                        boundingBoxes[index].style.left=(Math.round((((coordinates.x_min+coordinates.x_max)/(2 * widthDivider))))).toString()+"px"; 
                        boundingBoxes[index].style.borderRadius = "50%";
                        boundingBoxes[index].style.backgroundColor = "#8E9AAF";
                        boundingBoxes[index].style.paddingTop="1rem";
                        boundingBoxes[index].addEventListener("click",()=>
                        {
                            setCategoryIndex(parseInt(index));
                        })
                    }
                    imgDiv.appendChild(boundingBoxes[index])
                })
            } else if (imgDiv.childNodes.length>1) {
                for (let i = 1; i<imgDiv.childNodes.length; i++) {
                    imgDiv.removeChild(imgDiv.childNodes[i]);
                    let newReset = reset?false:true;
                    setReset(newReset);
                }
            }
        }
    } 
    useEffect(()=>{
        if(uploadResponse && Object.keys(uploadResponse).length > 0 ){
            if(uploadResponse.responseStatus === 200){
                setResponseResult(uploadResponse.responseJson.result)
                setIs_blurred(uploadResponse.responseJson.meta.is_blurred)
            }
            else if(uploadResponse.responseStatus === null || uploadResponse.responseStatus === undefined){
                setResponseResult(uploadResponse)
                setIs_blurred(false)
            }
        }
    }, [uploadResponse])
  
    useEffect(()=>{
        if(responseResult.length > 0 && firstLoaded){
            setTimeout(() => {
                createBoundingBoxes()
            }, 50)
            const element = document.getElementById('result')
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [ responseResult, categoryIndex, reset, firstLoaded])
   
    const openBookADemo = () => {
        // const div = document.querySelectorAll('.info-content');
        // for(let i=0; i< div.length; i++){
        //     if(div[i].classList.contains('hover')) {
        //         div[i].classList.remove('hover')
        //         setOpenCustomDemoForm(true)
        //     }
        // }
        setOpenCustomDemoForm(true)
    }
    return (
        <div id='result' className="ui container segment grid-section">

            <CategoriesDetected />
            <div className="ui grid at">
                <div className="wide column img-at at">
                    <div className="uploaded-image" id="uploaded-image">
                        <img src={uploadedImageURL} alt="image-AT" className="gridImage" id="image-upload" onLoad={()=>setTimeout(()=>setFirstLoaded(true), 50)} />
                    </div>
                </div>
                <div className="wide column attr-at at">
                    <div className="direction-column  at-segment">
                        <div className="direction-row padding category-selected-div " style={{paddingLeft:' 3.5rem'}}>
                            <div className="attributeTabLeft">
                                <h2 className="parahraph-attrType category-selected-h2">Category:</h2>
                            </div>
                            <div className="direction-row maxwidth">
                                <div className="ui segment at" >
                                    <p className="paragraph-tag category-selected at-cat-selected">{categorySelected && categorySelected.length > 0 ? categorySelected.split("_").join(' '): null}</p>
                                </div>
                            </div>
                        </div>
                        <div className='attr-no-blur'>
                        {/* show attr  */}
                        {selectedAttributesObject ? Object.keys(selectedAttributesObject).map((attrType, index) => {
                            if ( !selectedAttributesObject[attrType].includes('blurred') && selectedAttributesObject[attrType].length>0 ) {
                                return (
                                    <div key={index} className={is_blurred ?"attr-row direction-row padding ": 'direction-row padding '} style={{paddingLeft:' 3.5rem'}}>
                                        <div className="attributeTabLeft">
                                            <p className="parahraph-attrType attr">{attrType.replace(/_/g, " ")} </p>
                                        </div>
                                        <div className="direction-row maxwidth">
                                           
                                            {selectedAttributesObject[attrType].map((tag, ix) => {
                                                return (
                                                    <div className="ui segment at attr" key={ix}>
                                                        { <p className={ix>0 ? 'paragraph-tag border-left' : 'paragraph-tag'}>{tag}</p> }
                                                    </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        })
                            :null
                        }
                        </div>
                        {selectedAttributesObject && Object.values(selectedAttributesObject).flat().includes('blurred') ?
                        <div className='blur-container'> 
                            <div className="blur-div">
                                <div className='blur-container-content' >
                                <div className='btn-blur' onClick={()=>openBookADemo()}>
                                Let's talk
                                            {/* Request a pesonalized demo 
                                            <span className='mob-req-demo'> with one of our experts </span> */}
                                            <span style={{marginLeft:'0.8rem'}}>→</span>
                                        </div>
                                        <div className='description'>
                                            Discover more rich and detailed tags
                                        </div>
                                </div>
                            </div>
                            
                                {/* blurred tags  */}
                                {selectedAttributesObject ? Object.keys(selectedAttributesObject).map((attrType, index) => {
                                    if (attrType !== "style" && attrType !== "occasion" && selectedAttributesObject[attrType].includes('blurred') && selectedAttributesObject[attrType].length>0 ) {
                                        return (
                                            <div key={index} className="direction-row padding"  style={{paddingLeft:'3.5rem'}}>
                                            <div className="attributeTabLeft ">
                                                    <p className="parahraph-attrType blur">{attrType.replace(/_/g, " ")} </p>
                                                </div>
                                                <div className="direction-row maxwidth">
                                                
                                                    {selectedAttributesObject[attrType].map((tag, ix) => {
                                                        return (
                                                            <div className="ui segment at blur" key={ix}>
                                                                { <p style={{ userSelect: 'none'}} className={ix>0 ? 'paragraph-tag border-left' : 'paragraph-tag'}>{tag}-{attrType.replace(/_.*$/, "")}</p> }
                                                            </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                        }
                                    })
                                        :null
                                }
                                {selectedAttributesObject ? Object.keys(selectedAttributesObject).map((attrType, index) => {
                                            // console.log(attrType)
                                    if (attrType === "style" || attrType === "occasion" ) {
                                        if(selectedAttributesObject[attrType].includes('blurred')){
                                            return (
                                                <div key={index} className="direction-row padding " style={{paddingLeft:' 3.5rem'}}>
                                                    <div className="attributeTabLeft">
                                                        <p className="parahraph-attrType blur">{attrType.replace(/_/g, " ")}</p>
                                                    </div>
                                                    <div className="direction-row maxwidth">
                                                            {selectedAttributesObject[attrType].map((tag, ix) => {
                                                                return (
                                                                    <div className="ui segment at blur" key={ix}>
                                                                        {<p style={{ userSelect: 'none'}} className={ix>0 ? 'paragraph-tag border-left' : 'paragraph-tag'}>{tag}-{attrType.replace(/_.*$/, "")}</p>}
                                                                    </div>
                                                                    )
                                                                })
                                                            }
                                                    </div>
                                                </div>
                                                )
                                        }
                                    }
                                })
                                    :null
                                }
                            
                        </div>
                        :Object.keys(selectedAttributesObject).length <= 4 && Object.keys(selectedAttributesObject).length > 0 && is_blurred  ? 
                            <div className='blur-container'> 
                                <div className="blur-div">
                                    <div className='blur-container-content' >
                                        <div className='btn-blur' onClick={()=>openBookADemo()}>
                                            Request a pesonalized demo 
                                            <span className='mob-req-demo'> with one of our experts </span>
                                            <span  style={{marginLeft:'0.8rem'}}>→</span>
                                        </div>
                                        <div className='description'>
                                            Discover more rich and detailed tags
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                        : null }
                        </div>
                </div>
            </div>
        </div>
        // </div>
    )
}

export default ATGridPanel


 /* <div className="flexbox">
        <div>
        {attributeTypes ? attributeTypes.map((element, ix) => (
                <div key={ix} className="box">
                <h2 className="ui segment" style={{width: '100%', fontSize: '15px'}}>{capitalizeFirstLetter(element).replace(/_/g, " ")} :</h2>
                </div>
            ))
        : 
            null
        }
        </div>
        <div className="rightside box">
            {
                console.log(attributesTags)
            }
            {
                
                attributesTags ?    attributesTags.map((tagArray, ix) => {
                    console.log(tagArray)
                    return (
                        <div key={ix}>
                            {
                                tagArray.map((tag, index) => {
                                    return (
                                        <div className="box" key={index}>
                                            <h2 className="ui segment" style={{width: '100%', fontSize: '15px'}}> {capitalizeFirstLetter(tag).replace(/-/g, " ").replace(',', '-')}</h2>
                                        </div>
                                    ) })
                            }
                        </div>
                    )
                    })
                : null
            }
        </div>
    </div> */