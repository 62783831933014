import React, {useEffect} from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import homeImg from '../../assets/homeImg.png' 
import homeImg_mobile from '../../assets/homeImg_mobile.png'
import UploadImg from '../UploadImg/UploadImg'
import DemoImgAT from '../DemoImgAT/DemoImgAT'
import { useLocation } from "react-router-dom";

const Home =()=>{
    const location = useLocation();
    useEffect(()=>{ 
        const search = location.search;
        const paramss = new URLSearchParams(search);
        const utm_source = paramss.get('utm_source');
        const utm_medium = paramss.get('utm_medium');
        const utm_campaign = paramss.get('utm_campaign');
        let today = new Date();
        let expire = new Date();
        expire.setTime(today.getTime() + 3600000*24*14);
        let expires = expire.toGMTString();
        if(utm_source !== null)  document.cookie = `utm_source=${utm_source}; expires=${expires}; path=/;`
        if(utm_medium !== null) document.cookie = `utm_medium=${utm_medium}; expires=${expires}; path=/;`
        if(utm_campaign !== null) document.cookie = `utm_campaign=${utm_campaign}; expires=${expires}; path=/;`
    }, [])
   
    return (
        <>
        <Navbar/>
        <div className='homeContainer'>
            <div className="homeImg">
                <img src={homeImg} alt="" />
            </div>
            <div className="homeContent">
                <h1>Try Pixyle AI</h1>
                <h2>Experience a sneak peek and see part of Pixyle’s powerful capabilities with our sample demo</h2>
                <div className="homeImg-mobile">
                    <img src={homeImg_mobile} alt="" />
                </div>
                    {<UploadImg />}
                <div className='imgAt'>
                    <DemoImgAT sol={'at'}/>
                </div>
                <div className='privacy-policy home pp-home '>
                    By uploading an image you’re agreeing to <a  href="https://www.pixyle.ai/privacy" className='ppHome' >Pixyle’s Privacy Policy.</a>   
                </div>
            </div>
         
        </div>
        <Footer/>
        </>
    )
}
export default  Home;