import React, {useEffect, useState} from 'react';
import { useStore } from 'react-context-hook';
const DragAndDrop = (props) => {
    const dropRef = React.createRef();
    const [dragging, setDragging] = useStore('dragging',false);
    const[drop, setDrop] = useStore('drop', false);
    let dragCounter = 0;

    const handleDragIn = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter++;
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDragging(true);
        }
    }
    const handleDragOut = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter--;
        if (dragCounter > 0) return;
        setDragging(false);
     
    }
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
      
    }
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        setDrop(true)
     
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0 ) {
            props.handleDrop(e.dataTransfer.files);
            e.dataTransfer.clearData();
            dragCounter = 0;
        }
    }

    useEffect(() => {
            dragCounter = 0;
            let div = dropRef.current;
            div.addEventListener('dragenter', handleDragIn);
            div.addEventListener('dragleave', handleDragOut);
            div.addEventListener('dragover', handleDragOver);
            div.addEventListener('drop',handleDrop);
  
    }, [])

    return (
        <div className="dropzone" ref={dropRef} >
        {drop, dragging} 
            {props.children}    
        </div>
    )
}
export default DragAndDrop;