import {baseURL} from './baseURL'

const logout = async (token) => {
    let accessRevoke;
    let refreshRevoke;
    try {
      accessRevoke = await fetch(
        `${baseURL}/users/logout/access_revoke`,
        {
          method: "DELETE",
          headers: new Headers({
            Authorization: "Bearer " + token.access_token
          })
        }
      );
    } catch (error) {
      // console.log("error:", error);
    }
    try {
      refreshRevoke = await fetch(
        `${baseURL}/users/logout/refresh_revoke`,
        {
          method: "DELETE",
          headers: new Headers({
            Authorization: "Bearer " + token.refresh_token
          })
        }
      );
    } catch (error) {
      // console.log("error:", error);
    }

    // if (accessRevoke && refreshRevoke === 200) {
    // console.log("accessRevoke", accessRevoke, "refreshRevoke", refreshRevoke);
    localStorage.removeItem("loginToken");
    window.location.assign('/login');
    // }
  };

  export default logout