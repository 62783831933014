import React, {useEffect, useState} from 'react';
import ErrorModal from '../Modal/ErrorModal';
import login from '../../endpoints/login';
import img from "../../assets/img_login.svg"

const Login = () => {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("")
    const clickLogin = (event) => {
        event.preventDefault();
        event.stopPropagation();
        let data = {};
        data.username = userName;
        data.password = password;
        try {
            login(data).then (async res => {
                if(!res.status){
                    localStorage.setItem("loginToken", JSON.stringify(res));
                    window.location.assign('/');
                } else if( res.status === 429){
                    if(res.message){
                        setMessage('Rate Limit Exceeded. Please Try Again After Few Minutes!')
                    } else{
                        setMessage(res.detail)
                    }
                }
                else {
                    setMessage(res.detail)
                    }
                })
        } 
        catch(err){
            // console.log("Error: ", err)
        }
    }
    const closeErrModal = ()=>{
        setMessage("")
    }
    return (
        <div className="login-div">
        <div className="login-form-bg">
            <div className="login-form">
                <div className="login-img">
                    <img src={img} alt="Woman holding a phone illustration."/>
                </div>
                <div className="login-input">
                    <div className="column-direction">
                    <h1>Log In</h1>
                    <p>Upload your collections and let us do the heavy lifting</p>
                    <form className="" onSubmit={clickLogin}>
                    <div className="ui field">
                        <label>Username</label>
                        <input className="formField u-mrs"
                                type="text"
                                id="username"
                                name="userName"
                                placeholder="Username"
                                required
                                onChange={(e)=>setUserName(e.target.value)}
                            >
                        </input>
                    </div>
                    <div className="ui field">
                        <label>Password</label>
                        <input className="formField u-mrs"
                                type="password"
                                id="password"
                                name="password"
                                placeholder="Password"
                                required
                                onChange={(e)=>setPassword(e.target.value)}
                            ></input>
                    </div>

                      <div className="field  centerThis u-mrs">
                          <button className="round-purple-button btn" style={{padding:"0"}}>Log me in</button>
                      </div>
                    </form>
                    <div className="ui divider"></div>
                    <p>Having problems logging in? <a href="https://www.pixyle.ai/"><b>Contact Us</b></a> so we can solve it right away...</p>
                    {/* <p><b onClick={this.recoveryClick} style={{cursor:"pointer"}}>Forgot Password?</b> </p> */}
                    </div>
                </div>
            </div>
        </div>

        {message !== "" ? <ErrorModal  msg={message} exitFunc={closeErrModal} /> : null}


    </div>

    )
}
export default Login;