// export const baseURL = "http://192.168.5.10/dev2/v4"; // master
// // export const masterDemoToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MTIzNDcyNzYsIm5iZiI6MTYxMjM0NzI3NiwianRpIjoiMDI5NGMzZjgtZTI5ZS00NjQzLWI2Y2EtMTVmYzhkODU5MTM0IiwiaWRlbnRpdHkiOiIzMjBiY2EwYS01NzY2LTQ3MDMtYWZkNi00Mzc2NDQ3YTRkNDUiLCJmcmVzaCI6ZmFsc2UsInR5cGUiOiJhY2Nlc3MifQ.4CfvAE77tEA43hdpNIHBb5YgGqBaxHt40AoL6W7ys0c";
// // export const masterDemoToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MTE5MTIyNjYsIm5iZiI6MTYxMTkxMjI2NiwianRpIjoiYTM0ZmZlZTItZDQxMy00YWNhLWI4ZDYtYjkzNDFjOGQxOTc2IiwiaWRlbnRpdHkiOiJhZjhhMmEzOS1hYzE2LTQ4ZTItOGU0Yi0xZjM2NDA3YjhjZmMiLCJmcmVzaCI6ZmFsc2UsInR5cGUiOiJhY2Nlc3MifQ.20j2Pg7qyfGzyvnuueeOFmSj4gt14OIl2pxOdCn5DuM";
// export const masterDemoToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MzA1NzIxMzIsIm5iZiI6MTYzMDU3MjEzMiwianRpIjoiMjAwMmRkYjgtMDhkNy00NDM1LTg1YjItOGRjMzZlZjdhMDI4IiwiaWRlbnRpdHkiOiIzMjBiY2EwYS01NzY2LTQ3MDMtYWZkNi00Mzc2NDQ3YTRkNDUiLCJmcmVzaCI6ZmFsc2UsInR5cGUiOiJhY2Nlc3MifQ.21GhXpWhFksIpXr2aUdrkuu_23YDuDO0TvH1gCUIIIA"; //dev 
// export const masterDatasetId = 16364530863486004;
// export const vsURL = "http://192.168.5.10/dev2/v3";


// // staging
// export const baseURL = "https://staging-pva.pixyle.ai/v4"; // master
// export const masterDemoToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NjIzNjY1ODYsIm5iZiI6MTY2MjM2NjU4NiwianRpIjoiMzJmOTk2ZjQtZjA4My00MjM1LThkMzMtNTM5MGNkMWM4OWQ4IiwiaWRlbnRpdHkiOiJhZjhhMmEzOS1hYzE2LTQ4ZTItOGU0Yi0xZjM2NDA3YjhjZmMiLCJmcmVzaCI6ZmFsc2UsInR5cGUiOiJhY2Nlc3MifQ.95SBIKY_nVUwD3EDrYEoFNMEBAhQR3S7QMSBaaR2KqA'


// master
export const baseURL = "https://pva.pixyle.ai/v4"; // master
// export const demoToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MTIzNDcyNzYsIm5iZiI6MTYxMjM0NzI3NiwianRpIjoiMDI5NGMzZjgtZTI5ZS00NjQzLWI2Y2EtMTVmYzhkODU5MTM0IiwiaWRlbnRpdHkiOiIzMjBiY2EwYS01NzY2LTQ3MDMtYWZkNi00Mzc2NDQ3YTRkNDUiLCJmcmVzaCI6ZmFsc2UsInR5cGUiOiJhY2Nlc3MifQ.4CfvAE77tEA43hdpNIHBb5YgGqBaxHt40AoL6W7ys0c";
export const masterDemoToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MTE5MTIyNjYsIm5iZiI6MTYxMTkxMjI2NiwianRpIjoiYTM0ZmZlZTItZDQxMy00YWNhLWI4ZDYtYjkzNDFjOGQxOTc2IiwiaWRlbnRpdHkiOiJhZjhhMmEzOS1hYzE2LTQ4ZTItOGU0Yi0xZjM2NDA3YjhjZmMiLCJmcmVzaCI6ZmFsc2UsInR5cGUiOiJhY2Nlc3MifQ.20j2Pg7qyfGzyvnuueeOFmSj4gt14OIl2pxOdCn5DuM";
export const masterDatasetId = '16081322408194497';
export const vsURL = "https://pva.pixyle.ai/v3";
//Login url
export const baseLoginApiURL ="https://pva.pixyle.ai/v4/users"
