import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import imageFormat from '../../assets/imageFormat.png'
import noTags from '../../assets/noTags.png'
import timeOut from '../../assets/timeOut.png'

const ErrorModal = (props) => {

    const {msg, exitFunc, descriptionMsg, img, pic} = props;
    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
      }
    // const {msg, exitFunc, pic} = props;
// console.log(img)
    const exit = () => {
        document.body.style.overflowY = "auto";
        exitFunc();
    }

    useEffect(()=>{
        // setTimeout(()=>{document.getElementById("root").scrollIntoView({ behavior: 'smooth' })},100 )

        setTimeout(()=>{window.scrollTo({
            top:0,
            behavior: 'smooth',
        })}, 30 )
        // window.scrollTo(0, 0);
        document.body.style.overflowY = "hidden";
    }, [])

    return ReactDOM.createPortal(
        <div onClick={e=>{e.stopPropagation(); exit()}} className="ui dimmer modals visible active ">
             <div onClick={e=>e.stopPropagation()} className="ui standard modal visible active modal-class-error" style={{minWidth:"20%"}}>
             <div className="closeModal" ><i className="close icon" onClick={() => exit()}></i></div>
                <div className="content modal-content modal-content-error">
                    <div className='centerWithTable'>
                    {img == 'noTags'? <img className='error-modal-image' src={noTags} alt="errorModalImage" />:null}
                    {img == 'timeOut'? <img className='error-modal-image' src={timeOut} alt="errorModalImage" />:null}
                    {img == 'imageFormat'? <img className='error-modal-image' src={imageFormat} alt="errorModalImage" />:null}
                    
                    <div className="modal-title">{msg}</div> 
                   {descriptionMsg?<div className="modal-description">{descriptionMsg}</div>:null}  

                    <button onClick={exit} className={`button-create solution-at error-modal-btn`}>OK</button>
                    </div>
                </div>
                
             </div>
        </div>, document.getElementById('modal')
    )
}

export default ErrorModal;