import React, {useState, useEffect} from 'react'
import {withStore, useStore} from 'react-context-hook'

import img from '../../assets/img.png'
import uploadIcon from '../../assets/uploadIcon.png'
import noTags from '../../assets/noTags.png'
import {postSingleImage} from '../../endpoints/searchDemo'
import { getLimit } from '../../endpoints/getLimit'
import UploadingProgress from './UploadingProgress'
import Completed from './Completed'
import ErrorModal from '../Modal/ErrorModal';
import { useHistory } from "react-router-dom";
import EmailModal from '../Modal/EmailModal'
import DragAndDrop from '../Modal/DragAndDrop'
import LimitExceededModal from '../Modal/LimitExceededModal'
import conf from '../../config.json'
const UploadImg =({ at})=>{
    const [, setUploadResponse] = useStore('uploadResponse', {})
    const [ , setUploadedImageURL] = useStore('uploadedImageURL',null)
    const [uploadLeft, setuploadLeft] = useStore('uploadLeft', null)
    const [openCustomDemoForm, setOpenCustomDemoForm] = useStore('emailModal', false)
    const [, setSelectedImageName] = useStore('selectedImageName','')
    const [, setCategoryIndex] = useStore('categoryIndex', 0)

    const [textField, setTextField] = useState('')

    const [errorMsg, setErrorMsg] = useState(null)
    const [descriptionMsg, setDescriptionMsg] = useState(null)
    const [errImg, setErrImg] = useState(null)
    const [limitExceeded, setLimitExceeded] = useState(false)

    const [uploading, setUploading] = useState(false)
    const [complete, setComplete] = useState(false)
    const [dragging, setDragging] = useStore('dragging',false);
    const [firstLoaded, setFirstLoaded] = useStore('loaded', false)
    const internal = conf.InternalDemo.build
    const configToken = conf.Token
    const loginToken = JSON.parse(localStorage.getItem("loginToken"));
    let history = useHistory();
    const closeResModal=()=>{
        setLimitExceeded(null);
        setErrorMsg(null);
        setUploading(false)
    }
    const onChange = (e) => setTextField(e.target.value);
    const onSearch = async (e) => {
        e.preventDefault();
        setComplete(false)
        if(textField !== '') {
            setUploading(true)
            setTextField("")
            let searchDemoFunk = await postSingleImage(textField,internal,loginToken,configToken)
            // console.log(searchDemoFunk)
            if(searchDemoFunk && searchDemoFunk!==null && searchDemoFunk !== undefined && 
                searchDemoFunk.responseStatus !== 200) {
                if(searchDemoFunk.responseStatus === 204)
                    setErrorMsg(searchDemoFunk.responseJson.message)
                else if(searchDemoFunk.responseStatus === 429){
                    setUploading(false)
                    setComplete(false)
                    setLimitExceeded(searchDemoFunk.responseJson.message)
                }else if(searchDemoFunk.responseStatus === 422){
                        setErrImg('imageFormat')
                        setErrorMsg('Unsupported image format ')
                        setDescriptionMsg('Please try again by uploading one of our supported image formats - JPG, PNG or TIFF')
                        }
                    else if (searchDemoFunk.responseJson !==null && searchDemoFunk.responseJson !== undefined && !searchDemoFunk.responseStatus == 422 && searchDemoFunk.responseJson.detail)
                        setErrorMsg(searchDemoFunk.responseJson.detail)
                else if(searchDemoFunk.msg)
                    setErrorMsg(searchDemoFunk.msg)
                else{
                    setErrImg('timeOut')
                    setErrorMsg('Timeout Error')
                    setDescriptionMsg('Server timed out, please check your internet connection and try again. If the problem doesn’t resolve contact us here.')
                }
                   
            }
           
           
            if(searchDemoFunk.responseStatus==200 ){
                setFirstLoaded(false)
                // setUploadedImageURL(null)
                // console.log(searchDemoFunk , searchDemoFunk.responseJson.meta.counter_products)
                setTimeout(() => {
            
                    if(searchDemoFunk.responseJson.result.length>0){
                        setCategoryIndex(0)
                        setuploadLeft(searchDemoFunk.responseJson.meta.remaining_uploads)
                        setUploadResponse(searchDemoFunk)   
                        setUploadedImageURL(textField) 
                        setUploading(false)
                        setComplete(true)
                        setSelectedImageName('Uploaded Image')
                    }else  { 
                        setErrImg('noTags')
                        setErrorMsg("No tags detected")
                        setDescriptionMsg(
                            'Pixyle’s AI was unable to detect any fashion garment on the image that you uploaded. Please try using another image.'
                        )
                    }
                }, 1200); 
            }
        } else if(uploadLeft==0){
            <EmailModal setOpenCustomDemoForm={setOpenCustomDemoForm}/> 
        }
    }
    const uploadFunc =  async (file) => {
        setComplete(false)
        // e.preventDefault()
        if(file ) {
            setUploading(true)
            let res = await postSingleImage(file,internal, loginToken,configToken)
                if(res && res!==null && res !== undefined && res.responseStatus !== 200) {
                    if(res.responseStatus === 204)
                        setErrorMsg("No Predictions Found")
                    else if(res.responseStatus === 429){
                        setUploading(false)
                        setComplete(false)
                        setLimitExceeded(res.responseJson.message)
                    }
                    else if(res.responseStatus === 422){
                        setErrImg('imageFormat')
                        setErrorMsg('Unsupported image format ')
                        setDescriptionMsg('Please try again by uploading one of our supported image formats - JPG, PNG or TIFF')
                        }
                    else if (res.responseJson !==null && res.responseJson !== undefined && !res.responseStatus == 422 && res.responseJson.detail)
                        setErrorMsg(res.responseJson.detail)
                    else if(res.msg)
                        setErrorMsg(res.msg)
                    else{
                        setErrImg('timeOut')
                        setErrorMsg('Timeout Error')
                        setDescriptionMsg('Server timed out, please check your internet connection and try again. If the problem doesn’t resolve contact us here.')
                    }
            }
            if(res.responseStatus==200){
                setFirstLoaded(false)
                setTimeout(() => {
                    if(res.responseJson.result.length>0){
                        setCategoryIndex(0)
                        setUploadResponse(res)
                        setuploadLeft(res.responseJson.meta.remaining_uploads)
                        setUploadedImageURL(URL.createObjectURL(file))
                        setUploading(false)
                        setComplete(true)
                        setSelectedImageName('Uploaded Image')
                       
                    }else   {
                        setErrImg(noTags)
                        setErrorMsg("No tags detected")
                        setDescriptionMsg(
                            'Pixyle’s AI was unable to detect any fashion garment on the image that you uploaded. Please try using another image.'
                        )
                    }
            }, 1200); 
          }
        }else if(uploadLeft==0){
            <EmailModal setOpenCustomDemoForm={setOpenCustomDemoForm}/> 
        }
    }
    // const getUploadLimit = () => {
    //     if(uploadLeft === null) {
    //         getLimit().then(res => {
    //             // console.log(res)
    //             if(res.responseStatus === 200) {
    //                 if(res.responseJson.result.remaining_uploads>0 )
    //                 setuploadLeft(res.responseJson.result.remaining_uploads)
    //                 else setuploadLeft(0)
    //             }else if(res.hasError) {
    //                 console.log("Unable To Fetch Limit")
    //             }
    //         })
    //     }
    // }
    // useEffect(() => {
    //    getUploadLimit()
    // }, [])
    
    useEffect(()=>{
        if(complete){
            setTimeout(()=>{
                setComplete(false)
                if (window.location.pathname == '/')
                  return   history.push({
                    pathname: '/results'
                  })
            },800)
        }
    },[complete])
    const handleUpload = async (e) =>{
        e.preventDefault()
         let file = e.target.files[0]
         uploadFunc(file)
    }
    const handleDrop=(e)=>{
        let file = e[0];
        uploadFunc(file)
    }

    return (
        <>
        <DragAndDrop handleDrop={(e)=>handleDrop(e)}>
            <div  accept=".jpg, .jpeg, .png, .jfif" id={`demoImg-${at}`}  
                className={dragging ? `upload ${at} dropArea drop` : `dropArea upload ${at}`} >
                <div className={ uploading || complete? ' hide':dragging? 'show draggingImg' :'show' }>
                    {/* <div className={`uploadsLeft at`}>
                        <span className={`uploadsLeftImg ${at}`} ><img src={img} alt="" /></span>
                        <div className="numLeft"> <span className='num'>{uploadLeft}</span>
                        <span> daily uploads left</span></div>
                    </div>  */}
                    <div className={ uploading? 'uploadContainer hide' :'uploadContainer' }>
                        {/* <div className="columnUploadedImageButton btnDragnDrop">Upload an Image</div> */}
                        <label htmlFor  ="file" className="columnUploadedImageButton ">
                            <input type="file" name="file" id="file" accept=".jpg, .jpeg, .png, .jfif" 
                                    className="inputfile" 
                                    value=""
                                    onChange={handleUpload}/> 
                                    <img className='upload-icon' src={uploadIcon} alt='uploadIcon' /> Upload an Image  
                            {/* <span class="icon"><i class="upload icon"></i></span> */}
                        </label>
                        <div className="columnUploadedImageText">or</div>
                        <form onSubmit={onSearch} className={`columnUploadedImageInput ${at}` }>
                            <input type="text" id="name" name="name" placeholder="paste URL" className="inputLink" 
                                value={textField} 
                                onChange={onChange}
                                />
                                <button className={`urlButtonAutomatic ${at}`}  onClick={onSearch}>Search</button>
                        </form>    
                    </div>
                    
                    <div className={`privacy-policy home ${at}`}>
                        By uploading an image you’re agreeing to <a  href="https://www.pixyle.ai/privacy" className='ppHome' >Pixyle’s Privacy Policy.</a>   
                    </div>  
                </div>
                {dragging? <div className='draggingImgTxt'> Drop an image here <div className='dropLightTxt'>(1 at a time)</div></div> : null}
                {uploading? < UploadingProgress  />:null } 
                {complete ? <Completed/> : null}
                {errorMsg ? <ErrorModal img={errImg} descriptionMsg={descriptionMsg} msg={errorMsg} exitFunc={closeResModal} /> : null}
                {openCustomDemoForm?<EmailModal setOpenCustomDemoForm={setOpenCustomDemoForm}/>:null}
                
            </div>
        
        </DragAndDrop>
        <div  accept=".jpg, .jpeg, .png, .jfif"  
                className={`upload mobile-container-upload`} >
            <div className={ uploading || complete? ' hide ' :'show' }>
                        {/* <div className={`uploadsLeft at`}>
                            <span className={`uploadsLeftImg ${at}`} ><img src={img} alt="" /></span>
                            <div className="numLeft"> <span className='num'>{uploadLeft}</span>
                            <span> daily uploads left</span></div>
                        </div>  */}
                        <div className={ uploading? 'uploadContainer hide' :'uploadContainer' }>
                            {/* <div className="columnUploadedImageButton btnDragnDrop">Upload an Image</div> */}
                            <label htmlFor  ="file" className="columnUploadedImageButton ">
                                <input type="file" name="file" id="file" accept=".jpg, .jpeg, .png, .jfif" 
                                        className="inputfile" 
                                        value=""
                                        onChange={handleUpload}/> 
                                        <img className='upload-icon' src={uploadIcon} alt='uploadIcon' /> Upload an Image  
                                {/* <span class="icon"><i class="upload icon"></i></span> */}
                            </label>
                            <div className="columnUploadedImageText">or</div>
                            <form onSubmit={onSearch} className={`columnUploadedImageInput ${at}` }>
                                <input type="text" id="name" name="name" placeholder="paste URL" className="inputLink" 
                                    value={textField} 
                                    onChange={onChange}
                                    />
                                    <button className={`urlButtonAutomatic ${at}`}  onClick={onSearch}>Search</button>
                            </form>    
                        </div>
                        
                        <div className={`privacy-policy home ${at}`}>
                            By uploading an image you’re agreeing to <a  href="https://www.pixyle.ai/privacy" className='ppHome' >Pixyle’s Privacy Policy.</a>   
                        </div>  
            </div>
                {uploading? < UploadingProgress  />:null } 
                {complete ? <Completed/> : null}
                {errorMsg ? <ErrorModal img={errImg} descriptionMsg={descriptionMsg} msg={errorMsg} exitFunc={closeResModal} /> : null}
                {openCustomDemoForm?<EmailModal setOpenCustomDemoForm={setOpenCustomDemoForm}/>:null}
                {limitExceeded ? <LimitExceededModal msg={limitExceeded} exitFunc={closeResModal}/> : null}
        </div>
        </>
    )
}
export default  UploadImg;