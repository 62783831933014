import React, {useState, useEffect} from 'react'
import { useStore} from 'react-context-hook'
const UploadingProgress = (props) => {

  return (
      
      <div className='uploadingProgress'>
            <div className='comleted'>
                <div className='uploadMsg' >Uploading Image</div>
                <div className='progress'> 
                <div className="completed-progress"></div>
                </div>
            </div>
      </div>
  )

}
export default UploadingProgress;