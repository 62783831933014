import React, {useEffect, useState}from 'react';
import { NavLink } from 'react-router-dom';
import {withStore, useStore } from 'react-context-hook'; 
import conf from '../../config.json'
import logout from '../../endpoints/logout';
import Logo from '../../assets/logoN.png'
import logoutIcon from '../../assets/OOjs_UI_icon_upload_2.svg'
// import { useLocation } from 'react-router';
import EmailModal from '../Modal/EmailModal'
const Navbar =() => {
    const [openCustomDemoForm, setOpenCustomDemoForm] = useStore('book demo',false)
    const token=JSON.parse(localStorage.getItem("loginToken"));
    const internal = conf.InternalDemo.build
    return (
        <div className="u-pts u-center-text segment div-navbar">
            <nav className="navbar">
                    <li className="nav-item-image">
                        <a href="https://demo.pixyle.ai/">
                            <img src={Logo} alt="pix-logo"  className="ui small image logo-img"/>
                        </a>
                        {internal == 'true' ? 
                             <div className= "logOut-div" onClick={()=>logout(token)}>
                            <img src={logoutIcon} alt="logout icon"  style={{transform:"rotate(180deg)"}} className="svg-gray" />
                            <div className="solution-text logout">
                                Log Out
                            </div>
                        </div>
                        :null
                        }
                        
                    </li>
                    <ul className={'navbar-desktop'}>
                        <div className="mobile-div">
                            
                            <li className="nav-item">
                                <button className='buttonNavbar' onClick={()=>setOpenCustomDemoForm(true)}>Let's talk</button>
                            </li>
                            
                            
                        </div>
                    </ul>
                   
                    {openCustomDemoForm ? <EmailModal setOpenCustomDemoForm={setOpenCustomDemoForm}/> : null }
                    
            </nav>
        </div>
      
    )
}

export default Navbar
