import React, {useState, useEffect} from 'react'
import {useStore} from 'react-context-hook'
import ATstaticImages from '../../assets/at-jsons/at_images.json'
import { useHistory, useLocation } from "react-router-dom";

////sdfs
const DemoImgAT = ({at}) => {   
    let history = useHistory();
    const [uploadedImageURL, setUploadedImageURL] = useStore('uploadedImageURL', null)
    const [staticImgIndex, setStaticImgIndex] = useStore('staticImgIndex',0) 
    const [categoryIndex, setCategoryIndex] = useStore('categoryIndex', 0)
    const [detectedCategories , setdetectedCategories] = useStore('detectedCategories', [])
    const [, setSelectedAttributesObject] = useStore('selectedAttributesObject', {})
    const [selectedImageName, setSelectedImageName] = useStore('selectedImageName','')
    const [, setCategorySelected] = useStore('categorySelected', '')
    const [, setuploadResponse] = useStore('uploadResponse', {});
    const [staticImgs, setStaticImgs] = useState('')
    const [reseter] = useStore('reseter', false);
    const [autoTag, setAutoTag]= useState(false)
    const location = useLocation();
     

    useEffect (() =>{
           const ATheaderImages = ATstaticImages.map((img) => img.image) 
            setStaticImgs(ATheaderImages)
            // setAutoTag(false)
            // const ix = localStorage.getItem('ix');
            // const img = localStorage.getItem('img');
            // localStorage.removeItem("ix");
            // localStorage.removeItem("img");
            // handleClick(img, ix)
            
    }, [])
    
 // when changing a static img, get its' detected categories
    useEffect(() => {
        const staticCategory = ATstaticImages.map((img) => img.result.map((res) => res.category))[staticImgIndex]
        setdetectedCategories(staticCategory)
       // console.log(staticCategory[categoryIndex]);
        setCategorySelected(staticCategory[categoryIndex]) 
       
    },[staticImgIndex, reseter])



    // Use effect only when first accessing and there is nothing selected or going back from error modal
    useEffect(()=>{
            if(ATstaticImages && selectedImageName === '' && detectedCategories.length > 0 && location.pathname === '/results'){
                let toReturn = false;
                ATstaticImages.map( img => {
                    
                    if(!toReturn){
                        img.result.map((cat, index) => {
                            // console.log(cat, index)
                            if (index === 0) {
                                let attributes = cat.attributes
                                let obj = {}
                                attributes.map((atri) => {
                                    if(Object.keys(obj).includes(atri.attribute_type)) {
                                        obj[atri.attribute_type].push(atri.attribute)
                                    } else {
                                        obj[atri.attribute_type] = [atri.attribute]
                                    }
                                })
                                toReturn = true;
                                setuploadResponse(img.result)
                                setSelectedImageName(img.image);
                                setSelectedAttributesObject(obj);
                                setCategorySelected(detectedCategories[categoryIndex])
                                setUploadedImageURL(`images/${img.image}`);
                            }
                        })
                    }
                })
            }
        
    }, [detectedCategories, ATstaticImages, categoryIndex])
    
 //used for creating the object of tags and attributes, whenever there is change in selected img
    useEffect(() => {
            ATstaticImages.map((img) => {
                if(img.image === selectedImageName) {
                    
                    img.result.map((cat, index) => {
                        if(index === categoryIndex) {
                            let attributes = cat.attributes
                            let obj = {}
                            attributes.map((atri) => {
                                if(Object.keys(obj).includes(atri.attribute_type)) {
                                    obj[atri.attribute_type].push(atri.attribute)
                                } else {
                                    obj[atri.attribute_type] = [atri.attribute]
                                }
                            })
                            // console.log(obj)
                            setuploadResponse(img.result)
                            setSelectedAttributesObject(obj);
                            setCategorySelected(detectedCategories[categoryIndex])
                            setSelectedImageName(img.image);
                        }
                    })
                }
            })
    }, [selectedImageName, categoryIndex,  ATstaticImages ])

    useEffect(()=>{
        setCategorySelected(detectedCategories[0])
    }, [detectedCategories])


    const handleClick = (img, ix) => {
        setCategoryIndex(0)
        setSelectedImageName(img)
        setUploadedImageURL(`images/${img}`)
        setStaticImgIndex(ix)
        setuploadResponse({})
            ATstaticImages.forEach(staticImg => {
                if(staticImg.image === img){
                    setuploadResponse(staticImg.result)
                    if (window.location.pathname == '/')
                        setAutoTag(true)
                   }
            }) 
    }

    useEffect(()=>{
         if(autoTag){
             setAutoTag(false)
            return history.push('/results');
         }
    },[uploadedImageURL])


    return (
            <div  className={`demo-img demoImg-${at}`}>
                <div className="pictures-p-tag">
                    <p>Don’t have an image? You can use one of these...</p>
                </div>
                <div className="columnImg" id={`demoImg-${at}`}>
                    {
                        staticImgs ? staticImgs.map((img, ix) => {
                            return (
                                at == undefined && ix <= 3 ? 
                                <div key={ix} className="img-div" onClick={() => handleClick(img, ix)}>
                                    <img src={`images/${img}`}  className="img" alt="listed-images" />
                                </div>
                                : at=="at" && ix <= 5 ? 
                                <div key={ix} className="img-div" onClick={() => handleClick(img, ix)}>
                                    <img src={`images/${img}`}  className="img" alt="listed-images" />
                                </div> :null
                            )
                        })
                    : null}
                </div>
               
            </div>
         
    )
}

export default DemoImgAT