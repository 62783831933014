import {baseURL, masterDemoToken} from "./baseURL";
//form data should be formated
export const postSingleImage = async (file, internal, loginToken,configToken) => {
    //https://pva.pixyle.ai/v3/solutions/demo/auto-tag/image
    let responseStatus;
    let token;
    let url
    const formData = new FormData();
    formData.append("image", file);
    // formData.append("email", mail)
    // console.log(formData)
    if(internal == 'true'){ 
        token = loginToken.access_token;
        url = 'auto-tag/image'
    }else if(configToken.build== 'true'){
        token = configToken.accessToken
        url = 'demo/auto-tag/image'
    }else{ 
        token = masterDemoToken;
        url = 'demo/auto-tag/image'
    }
    try{
      return await fetch(`${baseURL}/solutions/${url}`, {
            method: "POST",
            headers: new Headers({
                "Authorization":`Bearer ${token}`
            }),
            body: formData
        })
        .then(response =>
            {
              responseStatus = response.status;
              // if(response.ok)
              // {
                // console.log("STATUS:", responseStatus)
                // console.log(response)
                if(responseStatus === 204) {
                    return {
                        "detail":"Unprocessable Entity",
                        "responseStatus":response.status
                    }
                }
                return response.json()
              // }
              // else ....
            })
        .then(responseJson => {
            if(responseStatus===200){
                return {responseStatus,responseJson,hasError:false}
               }
            else return {responseStatus,responseJson,hasError:true,msg:"Unable to upload."}
        })
        .catch(error => {
            console.error("Getting products error: "+ error);
            return error;
        })
    }
    catch(err) {
        console.error(`Error in try catch block ${err}`);
        return err;
    }
}