import React, { useEffect } from "react";
import { withStore, useStore } from 'react-context-hook';
import DemoImgAT from '../DemoImgAT/DemoImgAT'
import ATGridPanel from '../ATGridPanel/ATGridPanel'
import UploadImg from "../UploadImg/UploadImg";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";



const Autotag = () => {
    const [isLoading] = useStore('isLoading', false);

    useEffect(()=>{
        document.title = 'Demo | Pixyle.ai | Automatic Tagging' 
    }, [])

    useEffect(()=>{
    //    document.getElementById('header')?.scrollIntoView({ behavior: 'smooth' });
    const element = document.getElementById('header')
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
 
    // window.scrollTo({
    //     top: element,
    //     left: 0,
    //     behavior: 'smooth'
    //   });
    }, [])
    return(
        < >
            <Navbar/>
            <div className="atHeader " >
                <UploadImg at={'at'}/>
                <div className="demoImg" id='header'>
                    <DemoImgAT at={'at'}/>
                </div>

            </div>
            <ATGridPanel />
            <Footer/>
        </>
    )
}
export default Autotag;