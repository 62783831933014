import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Autotag from "./components/Autotag/Autotag";
import Home from './components/DemoHome/DemoHome'
import conf from '../src/config.json'
import Login from "./components/Login/Login"
import ReactGA from 'react-ga'
import {hotjar} from 'react-hotjar'
import "./sass/main.scss";
import { withStore } from 'react-context-hook';
import CookieBanner from './components/Cookie/CookieBanner'
import {getToken} from './components/validation/validation';
function App() {

  const [navbarOpen, setOpen] = useState(false);

   // useEffect(()=>{
  //   ReactGA.initialize('UA-115993727-1'); //analytics-statictics
  //   hotjar.initialize(1483118); // marketing
  // }, [])
  // useEffect(()=>{
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // },[window.location.pathname])
  // const [hubspotCookies, setHubspotCookies] = useState(null)
  // const [load, setLoaded] = useState(false)

  const [ showCookieIcon , setShowCookieIcon] = useState(false)
  const internal = conf.InternalDemo.build
  const production = conf.ProductionDemo.build
  useEffect(()=>{
      let c = document.cookie;
      if(!document.cookie=="")setShowCookieIcon(true)

      ReactGA.initialize('UA-115993727-2'); //analytics-statictics
      ReactGA.pageview(window.location.pathname + window.location.search);

      hotjar.initialize(1483118); // marketing

      // const cookies = document.cookie.split(";").reduce((ac, cv, i) => {
      //     const [key, val] = cv.split("=");
      //     return Object.assign(ac, { [key]: val });
      //   }, {});

        // let script = document.createElement('script')
        //   script.setAttribute('src', '//js.hs-scripts.com/6324919.js')
        //   script.setAttribute('id', 'hs-script-loader')
        //   document.body.appendChild(script) 
          
        //   script.onload = () => {
        //       setLoaded(true)
        //   }      

  },[])
  // let hubspotCookie
  // useEffect(()=>{
      
  //     const timeout = setTimeout(() => { 
  //         let c = document.cookie;
  //         const cookiess = document.cookie.split(";").reduce((ac, cv, i) => {
  //         const [key, val] = cv.split("=");
  //         return Object.assign(ac, { [key]: val });
  //         }, {});
  //         hubspotCookie = cookiess
  //         setHubspotCookies(hubspotCookie)
  //         for (var key in cookiess) {
  //             if (cookiess.hasOwnProperty(key) ) {
  //               if(window.localStorage.getItem('statistics') == 'false' || !statistics){
  //                 console.log('here')
  //                 if (key.includes('__hs')||key.includes('hub'))
  //                 console.log('__h')
  //                 document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  //                 document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.demo-frontend-dot-pixyle-development.uc.r.appspot.com`;
                 
  //               }
  //             }
  //         }
  //     }, 2000 )
  //     return () => {
  //         clearTimeout(timeout);  // this guarantees to run right before the next effect
  //       }
  // }, [load])

  return (
    <>
    <Router>
      {/* <Navbar  /> */}
      <Switch>
      <Route
          exact
          path="/"
          render={()=>(
            internal==='true' && !getToken()?
            <Redirect  to={"/login"} />
            :<Home/>
          )}/>
        <Route
          exact
          path="/results"
          render={()=>(
            internal==='true' && !getToken()?
              <Redirect  to={"/login"} />
            : <Autotag/>
          )}/>
        {internal==='true'?
          <Route
              exact
              path="/login"
              render={()=>(
                getToken()?
                <Redirect  to={"/"} />
                :  <Login/> 
                
              )}/>
        :null
        }
        
     
     
        <Route render={() => <Redirect to={{pathname: "/"}} />} />
      </Switch>
      {/* <Footer open={navbarOpen} setOpen={setOpen} /> */}
      { internal==='false'?
        <CookieBanner showCookieIcon={showCookieIcon} setShowCookieIcon={setShowCookieIcon}/>
      :null}
    </Router>

    
    </>
  );
}

export default withStore(App) ;
