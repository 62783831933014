import React, {useState, useEffect} from 'react';
// import { useStore } from 'react-context-hook';
import CookiePreferences from './CookiePreferences';
import cookie from '../../assets/svg/cookie.svg'
import ReactGA from 'react-ga'
import {hotjar} from 'react-hotjar'
const CookieBanner = (props) => {

    const [ openPreferences ,setOpenPreferences] = useState(false)
    const{hubspotCookies} = props
    const [showCookieIcon,setShowCookieIcon] =useState(false)
    const [marketing, setMarketing] = useState(false)
    const [statistics, setStatistics] = useState(true) 
  
    useEffect(()=>{
        if(!document.cookie=="")setShowCookieIcon(true)
    },[])
    const acceptAll = () => {
         // marketing
        // ReactGA.initialize('UA-115993727-1'); //analytics-statisctics
        // Object.entries(hubspotCookies).map( ([key, val] ) => {
        //     document.cookie =  `${key} = ${val}`;
        // });  //analytics-statisctics
        
        // window.localStorage.setItem('statistics','true')
        window.localStorage.setItem('marketing','true')
        setShowCookieIcon(true)
        // hotjar.initialize(1483118);
    }

    const confirmpreferences = () => {
        if(marketing && !statistics){
           
            window.localStorage.setItem('marketing','true')
            window.localStorage.setItem('statistics','false')
            // removeStatistics()
            // hotjar.initialize(1483118);
        }else if(statistics && !marketing){
            ReactGA.initialize('UA-115993727-1');
          
            window.localStorage.setItem('statistics','true')
            window.localStorage.setItem('marketing','false')
            // removeMarketing()
         
        }else if(marketing && statistics){
            // ReactGA.initialize('UA-115993727-1'); 
            // hotjar.initialize(1483118); 
            
            window.localStorage.setItem('statistics','true')
            window.localStorage.setItem('marketing','true')
        
        }else {
            window.localStorage.setItem('marketing','false')
            // window.localStorage.setItem('statistics','false')
            // removeMarketing()
            // removeStatistics()
        }
    }
    const removeStatistics = ()=>{
        const cookies = document.cookie.split(";").reduce((ac, cv, i) => {
            const [key, val] = cv.split("=");
            return Object.assign(ac, { [key]: val });
          }, {});
      
        for (var key in cookies) {
            if (cookies.hasOwnProperty(key) ) {
               if (key.includes('ga')||key.includes('gi') )
                document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
                document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.demo-frontend-dot-pixyle-development.uc.r.appspot.com`;

            }
          }
    }
    const removeMarketing = ()=>{
        const cookies = document.cookie.split(";").reduce((ac, cv, i) => {
            const [key, val] = cv.split("=");
            return Object.assign(ac, { [key]: val });
          }, {});
      
        for (var key in cookies) {
            if (cookies.hasOwnProperty(key) && key.includes('hj')) {
                document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
                document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.demo-frontend-dot-pixyle-development.uc.r.appspot.com`;
            }
          }
    }
    const denyAll = () => {
        // const cookies = document.cookie.split(";").reduce((ac, cv, i) => {
        //     const [key, val] = cv.split("=");
        //     return Object.assign(ac, { [key]: val });
        //   }, {});
      
        // for (var key in cookies) {
        //     if (cookies.hasOwnProperty(key)) {
        //         document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.demo-frontend-dot-pixyle-development.uc.r.appspot.com`;
        //         document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        //     }
        //   }
        // document.cookie = "cookie=1";
        // window.localStorage.setItem('statistics','false')
        // removeMarketing()
        window.localStorage.setItem('marketing','false')
        setShowCookieIcon(true)
    }
    const prefsObj={
        marketing, 
        setMarketing,
        statistics, 
        setStatistics,
        denyAll, 
        acceptAll, 
        setOpenPreferences, 
        setShowCookieIcon,
        hubspotCookies,
        removeMarketing, removeStatistics, confirmpreferences
    }
    return (

        <div className='cookie-banner-components'>
            {!showCookieIcon ?
           <div className={openPreferences? 'cookie-pref-open':'cookie-banner-component'}>
                <div className='cookie-banner-container'>
                    <div className='cookie-banner-text'>
                            Pixyle.ai uses cookies to ensure you get the best experience on our website. 
                            <span><a href="https://www.pixyle.ai/cookie-policy" target="_blank"> Cookies</a></span>
                    </div>
                    <div className='cookie-banner-btns'>
                            <div className='cookie-banner-btn-preferences' onClick={() => setOpenPreferences(true)} >Preferences</div>
                            <div className='cookie-banner-btn-deny columnUploadedImageButton' onClick = {()=> denyAll()}>Deny</div>
                            <div className='cookie-banner-btn-accept columnUploadedImageButton' onClick = {()=> acceptAll()}>Accept</div>
                    </div>
                </div>
           </div> :
            <div className="cookie-icon-container" onClick={() => setOpenPreferences(true)}>
                <div className="cookie-icon">
                        <img src={cookie} alt="" style={{maxHeight:"7.5rem"}}/>
                </div>
            </div>
            }
           {openPreferences? <CookiePreferences prefsObj={prefsObj}  ReactGA={ReactGA} hotjar={hotjar} />:null}

        </div>
        
    )
}

export default CookieBanner
